import moment from 'moment';

const date = new Date();
date.setDate(date.getDate() - 1)

const settings = {
  isFluid: true,
  isRTL: false,
  isDark: false,
  navbarPosition: 'combo',
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: 'transparent',
  isConfigDashboard: false,
};

const filters = {
  region_stat: [],
  operation_center_stat: [],
  tags_stat: [],
  site_stat: [],
  site_classification_stat: [],
  province_stat: [],
  performance_stat: [],
  from_date_stat: date,
  to_date_stat: date,
  type_date_stat: 'specify_date_range',
  group_by: 1,
  year_trend: moment(date).format("YYYY"),
  month_trend: moment(date).format("MM"),
  region_trend: [],
  operation_center_trend: [],
  tags_trend: [],
  site_trend: [],
  site_classification_trend: [],
  province_trend: [],
  performance_trend: [],
  type_date_trend: 'specify_date_range',
  region_live: [],
  operation_center_live: [],
  site_live: [],
  tags_live: [],
  site_classification_live: [],
  province_live: [],
  status_live: [],
  performance_live: [],
  user_filter: null,
};

const site_settings_filters = {
  region: [],
  operation_center: [],
  province: [],
  building_config: [],
  classification: [],
};

const perf_settings_filters = {
  region: [],
  operation_center: [],
  province: [],
  tags: [],
  classification: [],
  site: [],
  sla: [],
  output: [],
  tx_channel: [],
};

const configuration = { 
  version: '1.3.0', 
  navbarBreakPoint: 'xl', 
  topNavbarBreakpoint: 'lg', 
  settings: settings, 
  filters: filters,
  site_settings_filters: site_settings_filters,
  perf_settings_filters: perf_settings_filters
}

export default configuration;