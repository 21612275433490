import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import AppContext from 'context/Context';
import configuration from './config';
import { getColor, getItemFromStore } from 'helpers/utils';
import { configReducer } from './reducers/configReducer';
import { filterReducer } from './reducers/filterReducer';
import useToggleStyle from './hooks/useToggleStyle';

const Main = props => {

  const configState = {
    isFluid: getItemFromStore('isFluid', configuration.settings.isFluid),
    isRTL: getItemFromStore('isRTL', configuration.settings.isRTL),
    isDark: getItemFromStore('isDark', configuration.settings.isDark),
    isConfigDashboard: getItemFromStore('isConfigDashboard', configuration.settings.isConfigDashboard),
    navbarPosition: getItemFromStore('navbarPosition', configuration.settings.navbarPosition),
    isNavbarVerticalCollapsed: getItemFromStore(
      'isNavbarVerticalCollapsed',
      configuration.settings.isNavbarVerticalCollapsed
    ),
    navbarStyle: getItemFromStore('navbarStyle', configuration.settings.navbarStyle),
    currency: configuration.settings.currency,
    showBurgerMenu: configuration.settings.showBurgerMenu,
    showSettingPanel: false,
    navbarCollapsed: false,
    showFilterPanel: false,
    showPanelPerformances: false,
    showPanelSites: false,
  };

  const configFilter = {
    user_filter: getItemFromStore('user_filter', configuration.filters.user_filter),

    //Performances Statistincs
    region_stat: getItemFromStore('region_stat', configuration.filters.region_stat),
    operation_center_stat: getItemFromStore('operation_center_stat', configuration.filters.operation_center_stat),
    tags_stat: getItemFromStore('tags_stat', configuration.filters.tags_stat),
    site_stat: getItemFromStore('site_stat', configuration.filters.site_stat),
    site_classification_stat: getItemFromStore('site_classification_stat', configuration.filters.site_classification_stat),
    from_date_stat: getItemFromStore('from_date_stat', configuration.filters.from_date_stat),
    to_date_stat: getItemFromStore('to_date_stat', configuration.filters.to_date_stat),
    province_stat: getItemFromStore('province_stat', configuration.filters.province_stat),
    performance_stat: getItemFromStore('performance_stat', configuration.filters.performance_stat),
    group_by: getItemFromStore('group_by', configuration.filters.group_by),
    type_date_stat: getItemFromStore('type_date_stat', configuration.filters.type_date_stat),

    //Performances Trend
    year_trend: getItemFromStore('year_trend', configuration.filters.year_trend),
    month_trend: getItemFromStore('month_trend', configuration.filters.month_trend),
    region_trend: getItemFromStore('region_trend', configuration.filters.region_trend),
    operation_center_trend: getItemFromStore('operation_center_trend', configuration.filters.operation_center_trend),
    tags_trend: getItemFromStore('tags_trend', configuration.filters.tags_trend),
    site_trend: getItemFromStore('site_trend', configuration.filters.site_trend),
    site_classification_trend: getItemFromStore('site_classification_trend', configuration.filters.site_classification_trend),
    province_trend: getItemFromStore('province_trend', configuration.filters.province_trend),
    performance_trend: getItemFromStore('performance_trend', configuration.filters.performance_trend),
    type_date_trend: getItemFromStore('type_date_trend', configuration.filters.type_date_trend),

     //Performances Live
     status_live: getItemFromStore('status_live', configuration.filters.status_live),
     performance_live: getItemFromStore('performance_live', configuration.filters.performance_live),
     region_live: getItemFromStore('region_live', configuration.filters.region_live),
     site_live: getItemFromStore('site_live', configuration.filters.site_live),
     operation_center_live: getItemFromStore('operation_center_live', configuration.filters.operation_center_live),
     tags_live: getItemFromStore('tags_live', configuration.filters.tags_live),
     site_classification_live: getItemFromStore('site_classification_live', configuration.filters.site_classification_live),
     province_live: getItemFromStore('province_live', configuration.filters.province_live),
  };

  const configSiteSettingsFilters = {
    region: getItemFromStore('region', configuration.site_settings_filters.region),
    operation_center: getItemFromStore('operation_center', configuration.site_settings_filters.operation_center),
    province: getItemFromStore('province', configuration.site_settings_filters.province),
    building_config: getItemFromStore('building_config', configuration.site_settings_filters.building_config),
    classification: getItemFromStore('classification', configuration.site_settings_filters.classification),
  };

  const configPerfSettingsFilters = {
    region: getItemFromStore('region', configuration.perf_settings_filters.region),
    operation_center: getItemFromStore('operation_center', configuration.perf_settings_filters.operation_center),
    province: getItemFromStore('province', configuration.perf_settings_filters.province),
    tag: getItemFromStore('tag', configuration.perf_settings_filters.tag),
    classification: getItemFromStore('classification', configuration.perf_settings_filters.classification),
    site: getItemFromStore('site', configuration.perf_settings_filters.site),
    sla: getItemFromStore('sla', configuration.perf_settings_filters.sla),
    output: getItemFromStore('output', configuration.perf_settings_filters.output),
    tx_channel: getItemFromStore('tx_channel', configuration.perf_settings_filters.tx_channel),
  };

  const [config, configDispatch] = useReducer(configReducer, configState);
  const [filter, filterDispatch] = useReducer(filterReducer, configFilter);
  const [site_settings_filters, siteSettingsFiltersDispatch] = useReducer(filterReducer, configSiteSettingsFilters);
  const [perf_settings_filters, perfSettingsFiltersDispatch] = useReducer(filterReducer, configPerfSettingsFilters);

  const { isLoaded } = useToggleStyle(
    config.isRTL,
    config.isDark,
    configDispatch,
    filterDispatch,
    siteSettingsFiltersDispatch,
    perfSettingsFiltersDispatch
  );

  const setFilter = (key, value) => {
    filterDispatch({
      type: 'SET_FILTER',
      payload: {
        key,
        value,
        setInStore: [
          'user_filter',
          'region_stat',
          'operation_center_stat',
          'tags_stat',
          'site_stat',
          'site_classification_stat',
          'from_date_stat',
          'to_date_stat',
          'province_stat',
          'performance_stat',
          'type_date_stat',
          'group_by',
          'year_trend',
          'month_trend',
          'region_trend',
          'operation_center_trend',
          'tags_trend',
          'site_trend',
          'site_classification_trend',
          'province_trend',
          'performance_trend',
          'type_date_trend',
          'status_live',
          'performance_live',
          'region_live',
          'operation_center_live',
          'site_live',
          'tags_live',
          'site_classification_live',
          'province_live',
        ].includes(key)
      }
    });
  };

  const setSiteSettingsFilters = (key, value) => {
    siteSettingsFiltersDispatch({
      type: 'SET_FILTER',
      payload: {
        key,
        value,
        setInStore: [
          'region',
          'operation_center',
          'province',
          'building_config',
          'classification',
        ].includes(key)
      }
    });
  };

  const setPerfSettingsFilters = (key, value) => {
    perfSettingsFiltersDispatch({
      type: 'SET_FILTER',
      payload: {
        key,
        value,
        setInStore: [
          'region',
          'operation_center',
          'province',
          'tag',
          'classification',
          'site',
          'sla',
          'output',
          'tx_channel'
        ].includes(key)
      }
    });
  };

  const setConfig = (key, value) => {
    configDispatch({
      type: 'SET_CONFIG',
      payload: {
        key,
        value,
        setInStore: [
          'isFluid',
          'isRTL',
          'isDark',
          'isConfigDashboard',
          'navbarPosition',
          'isNavbarVerticalCollapsed',
          'navbarStyle',
        ].includes(key)
      }
    });
  };

  if (!isLoaded) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: config.isDark ? getColor('dark') : getColor('light')
        }}
      />
    );
  }

  return (
    <AppContext.Provider value={{ config, setConfig, filter, setFilter, site_settings_filters, setSiteSettingsFilters, perf_settings_filters, setPerfSettingsFilters }}>
      {props.children}
    </AppContext.Provider>
  );
};

Main.propTypes = { children: PropTypes.node };

export default Main;
